import React, { useContext, useEffect, useState, Component } from 'react'
import { socket } from '../realtime'
import {
    GlobalDispatchContext,
    GlobalStateContext,
} from "../context/GlobalContextProvider"
import { generate } from 'shortid'
import p5w from '../components/p5w'
import chats from '../components/sketches/chats'
import test from '../components/sketches/test'
import spinner from '../images/spinner.gif';

import Helmet from "react-helmet"

const P5W = p5w(generate())
export default (props) => {


    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)

    useEffect(() => {

        const whomai = setInterval(() => {
            dispatch({ type: "BROADCAST" })
        }, state.room ? 4000 : 10000);

        const cleanStreams = setInterval(() => {
            dispatch({ type: "CLEAN_STREAMS" })
        }, 1000);

        const setup = setInterval(() => {
            let tinput = document.getElementById('hidden-text');
            if (tinput) {
                tinput.blur();
                tinput.focus();
                tinput.click();
                clearInterval(setup)
            }
        }, 1000);

        setTimeout(() => {
            document.getElementById('chat-instructions').style.display = 'none';
        }, 20000);

        setTimeout(() => {
            window.location.reload(true);
        }, 1000 * 3600 * 24.01)



        return () => {
            clearInterval(whomai);
            clearInterval(cleanStreams);
        }

    }, []);

   
    return (
        <>
            <Helmet title="Ephemeral Chat" defer={false} />
            <div style={{ position: 'fixed', left: 12, top: 12, width:30, height:100, zIndex: 9999999 }}>
                <div style={{ position: 'absolute' }}>
                    <div id="p5_loading"><img src={spinner} style={{ width: 30, height: 30, marginTop: 12 }} /></div>
                </div>
            </div>
            <input type="text" id="hidden-text" style={{ position: 'fixed', backgroundColor: '#000000', border: 0, opacity: 0.01, zIndex: 0, left: -400, top: -100 }}></input>
            {/* <P5W dispatch={dispatch} sketch={test} state={state}/> */}
            <P5W dispatch={dispatch} sketch={chats} state={state} />
            <p id="chat-instructions" style={{ fontFamily: 'helvetica,roboto,open-sans,arial', color: '#999999', zIndex: 99, position: 'fixed', bottom: 0, left: 12, right: 0 }}>V0.3 WORKS BEST ON CHROME.<br /> Click somewhere and type to start chatting in place.<br />Use the lock to go between private and public rooms.<br />You can join a peer to peer VIDEO conference after you click the video icon in a PRIVATE ROOM.<br />Share your room's browser link with friends and leave that desktop window open!<br />Your sound and font is unique to you and your friends and you'll be notified when someone joins.<br />Page not working? (It's alpha) But just hit refresh!<br />This is the LEAST CREEPY chat space ever! Ephemeral=NOTHING is saved... ever. Welcome! :)</p>
        </>
    )
}
