
import { fonts as ft } from '../../../package.json';
import {
    GlobalDispatchContext,
    GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { v1 as uuidv1 } from 'uuid';
const nextRoom = uuidv1();
export default function (s) {
    //s.state = {}
    // s.dispatch = () => { }
    let cabin;
    let fonts = {}
    let sounds = {}
    let setup = false;
    let ksetup = false;
    let videos = {};
    let locked = null, unlocked = null, videoInactive = null, videoActive = null;
    s.preload = () => {
        cabin = s.loadFont('/fonts/chats/cabin.ttf');
        fonts['/fonts/chats/cabin.ttf'] = cabin;
        locked = s.loadImage('/images/locked.png');
        unlocked = s.loadImage('/images/unlocked.png');
        videoInactive = s.loadImage('/images/video-camera.png');
        videoActive = s.loadImage('/images/video-camera-active.png');
        // Test Loading fonts - compatibility with p5
        // for (var i=0 ; i < ft.length; i++ )
        // {
        //     console.log('/fonts/chats/' + ft[i])
        //     const temp = s.loadFont('/fonts/chats/' + ft[i], () => {

        //     });
        // }
    }

    s.setup = () => {
        s.masterVolume(0.2);
        if (!setup) {
            let start = s.loadSound('/sounds/doorbell.mp3', () => {
                start.play();
            })
            setup = true;
        }
        s.createCanvas(window.innerWidth, window.innerHeight); // use parent to render canvas in this ref (without that p5 render this canvas outside your component)        

        window.onresize = () => {
            s.canvas.width = window.innerWidth;
            s.canvas.height = window.innerHeight;
            s.setup();
        }

        console.log('::: displayDensity:', s.displayDensity())
        console.log('::: pixelDensity:', s.pixelDensity())
        console.log('::: Chats has been initialized')

        GlobalStateContext.on('play', (m) => {
            if (sounds[m]) {
                sounds[m].play();
            } else {
                let sound = s.loadSound(m, () => {
                    sounds[m] = sound;
                    sound.play();
                })
            }
        });

        GlobalStateContext.on('reconnect-video', ({ key, streams }) => {
            console.log("::Reconnecting video for", key, streams)
            if (streams && key != s.state.vid) {
                s.dispatch({ type: "UPDATE_PEER_MEDIA", key, streams })
                if (videos[key] && videos[key].elt) {
                    videos[key].elt.remove();                    
                } 
                videos[key] = s.createVideo();
                videos[key].elt.autoplay = true;
                videos[key].elt.muted = false;
                videos[key].position(-10000, -10000)
                try {
                    videos[key].elt.srcObject = streams[0];
                } catch (ex) {
                    console.log("Couldn't attach video", ex)
                }
                try { 
                    clearInterval(videos[key]._playinterval)
                } catch {}
                videos[key]._playinterval = setInterval(() => {
                    try {
                        //if (!navigator.userAgent.match(/Firefox/i))
                        videos[key].elt.play()
                    } catch (ex) {
                        console.log("Error playing peer media", ex)
                    }
                }, 4000)
                
            }
        });

        GlobalStateContext.on('stop-video', ({ key, stream }) => {
            console.log("::Stopping video for", key)
            try {
                if (videos[key]) {
                    videos[key].stop()
                    videos[key].elt.srcObject = undefined;
                }
            }
            catch { }
        });

    }

    s.draw = () => {
        //console.log(fonts)
        s.background(0);
        if (s.state) {
            if (s.state.room) {
                s.image(locked, 11, 12, 30, 30)
                if (s.state.shareMedia)
                    s.image(videoActive, 12, 48, 30, 30)
                else
                    s.image(videoInactive, 12, 48, 30, 30)
            }
            else {
                s.image(unlocked, 11, 12, 30, 30)
            }
            //if (videos[s.state.vid] && s.state.shareMedia) s.image(videos[s.state.vid], window.innerWidth - 64, 0, 64, 48);
            s.textSize(19);
            for (let [k, v] of Object.entries(s.state.ids)) {
                const stream = s.state.streams[k];
                //console.log(stream,v)
                if (stream && stream.length > 0) {
                    let chat = stream[0].msg;
                    for (let i = 1; i < stream.length; i++)
                        chat += " " + stream[i].msg
                    if (v) {
                        if (typeof fonts[v.font] == 'undefined') {
                            try {
                                fonts[v.font] = cabin;
                                const temp = s.loadFont(v.font, () => {
                                    fonts[v.font] = temp;
                                });
                            } catch { }
                            s.textFont(cabin);
                        } else {
                            s.textFont(v.font);
                        }
                        s.fill(v.color);
                        s.text(chat, v.pos.x, v.pos.y, v.pos.x + v.pos.w, v.pos.y + v.pos.h)
                    } else {
                        s.textFont(cabin);
                        s.fill(255, 255, 255);
                        s.text(chat, 0, 100, 100, 500)
                    }
                }
                try {
                    if (videos && videos[k] && v && v.pos) s.image(videos[k], v ? v.pos.x : 0, (v ? v.pos.y : 100) - 48, 64, 48)
                } catch { }
            }
        }
        // NOTE: Do not use setState in draw function or in functions that is executed in draw function... pls use normal variables or class properties for this purposes        
    };

    s.mousePressed = () => {
        document.getElementById('hidden-text').blur();
        if (s.mouseX && s.mouseY) {
            if (s.mouseX < 48 && s.mouseY < 42) {
                window.location.href = s.state.room ? "/" : "/?room=" + nextRoom
            } else if (s.mouseX < 48 && s.mouseY < 84 && s.state) {
                if (!s.state.shareMedia) {
                    s.dispatch({ type: 'RESET_PEER_DATA' })
                    let constraints = {
                        video: {
                            mandatory: {
                                maxWidth: 64,
                                maxHeight: 48
                            },
                            optional: [{ maxFrameRate: 10 }]
                        },
                        audio: true
                    };
                    if (!videos[s.state.vid]) {
                        videos[s.state.vid] = s.createVideo();
                        videos[s.state.vid].position(-10000, -10000)
                    }
                    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                        videos[s.state.vid].elt.srcObject = stream
                        videos[s.state.vid].elt.muted = true;
                        videos[s.state.vid].elt.autoplay = true;
                        videos[s.state.vid].elt.play()
                        GlobalStateContext.trigger('reconnect-video', { key: s.state.vid, stream })
                        s.dispatch({ type: 'UPDATE_MEDIA', data: stream })
                        s.dispatch({ type: 'TOGGLE_SHARE_MEDIA' })
                    });
                } else {
                    s.dispatch({ type: 'TOGGLE_SHARE_MEDIA' })
                }                
            } else {
                s.dispatch({ type: "UPDATE_ME", data: { pos: { x: s.mouseX, y: s.mouseY, w: s.state.ids[s.state.vid].pos.w, h: s.state.ids[s.state.vid].pos.h } } })
            }
        }
        document.getElementById('hidden-text').focus();
        document.getElementById('hidden-text').click();
        return false;
    };

    s.keyTyped = () => {
        if (!navigator.userAgent.match(/Android/i))
            s.dispatch({ type: "SEND_KEY", data: { key: s.key } })
    }

    s.keyPressed = () => {
        if (s.keyCode == 8 || s.keyCode == 46 || s.keyCode == 32) {
            let tinput = document.getElementById('hidden-text');
            if (tinput) {
                tinput.value = '';
                tinput.focus();
                tinput.click();
            }
        }
        s.dispatch({ type: "SEND_KEY", data: { keyCode: s.keyCode } })
    }

    if (!ksetup) {
        ksetup = true;
        let last = Date.now();
        let lastKey = "";
        const android = setInterval(() => {
            let tinput = document.getElementById('hidden-text');
            if (tinput) {
                clearInterval(android)
                tinput.addEventListener('input', (e) => {
                    // alert(e.data)
                    //console.log(e)
                    if (e.data && e.data.length > 0) {
                        const newKey = e.data[e.data.length - 1];
                        if (navigator.userAgent.match(/Android/i) && (lastKey != newKey || (Date.now() - last) > 300)) {
                            last = Date.now()
                            s.dispatch({ type: "SEND_KEY", data: { key: newKey.toLowerCase() } })
                            lastKey = newKey
                            tinput.value = '';
                        }
                        // document.getElementById('hidden-text').focus();
                        // document.getElementById('hidden-text').click();
                    }
                });
            }
        }, 1000)
    }
}




