import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { generate } from 'shortid'

export default function (id = generate(), className = "p5") {
    let canvas = null
    function P5W({
        sketch = () => { },
        state = {},
        dispatch = () => { },
        style = {}
    }) {
        console.log(`::: P5W(${id}) component has been re-rendered`)

        const sketchContainer = useRef(null)

        useEffect(() => {
            console.log(`::: P5W(${id})/useEffect()`)
            canvas = new window.p5(sketch, sketchContainer.current)
            canvas.state = state
            canvas.dispatch = dispatch

            return () => {
                console.log(`::: P5W(${id})/useEffect.return()`)
                canvas.remove()
            }
        }, [dispatch, sketch, state])

        return (
            <div ref={sketchContainer} className={className} style={style}>                
            </div>
        )
    }

    P5W.propTypes = {
        state: PropTypes.object,

        dispatch: PropTypes.func,
        sketch: PropTypes.func,
    }

    P5W.defaultProps = {
        state: {},

        dispatch: () => { },
        sketch: () => { },
    }

    return memo(P5W, (_, nextProps) => {
        if (canvas) canvas.state = { ...nextProps.state }

        return true
    })
}
